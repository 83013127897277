.navContainer{
    width: 97%;
    height: 100%;
    background-color: #5271FF;
    display:flex;
  justify-content:space-around;
  justify-items: flex-end;
  margin-left: 10px;
 
     
}
.navMenu{
    color: #F9F7DC;
    font-size: 1.5vmax;
    font-weight: bolder;  
    
}