.portafolioContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 97%;   
    margin: 10px;
}
.portafolioTitle{
    font-size: 3em;
    margin: 5px;
    margin-top: 38px;
    color: #03989E;
    font-weight:900;
}
.portafolioStart{
    font-size: 3vmax;
    margin: 10px;
    color: #03989E;
}
.portafolioContentImages{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 50% 50%;
    justify-content: space-between;
    align-content: center;
}
.portafolioImg{
    width: 100%;
  
    padding: 10px;
}