.contactContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 97%;
    margin-left: 10px;
    
}
.contactTitle{
    font-size: 3em;
    font-weight:900;
    color: #03989E;
}

.contactStar{
    color: #03989E;
}
.contactForm{
    font-size: 1.5vmax;
    color: #03989E;
}