.aboutContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    width: 97%;  
     background-color: #03989E;
     margin-left: 10px;
   }
.aboutTitle{
    font-size: 3em;
    margin: 5px;
    margin-top: 38px;
    color:#F9F7DC;
    font-weight:900;
}

.aboutStart{
    font-size: 4vmax;
    margin: 0px;
    color: #CB7F7A
}
.aboutContainerParagraph{
    display: grid;
    width: 80%;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
}
.aboutParagraph1{
    font-size: 1.5vmax;
    text-align: start;
    color: #F9F7DC;
    justify-self: flex-start;
    font-weight:600;
 
}
.aboutParagraph2{
    font-size: 1.5vmax;
    color: #F9F7DC;
    text-align: start;
    justify-self: flex-end;
    font-weight:600;
}