.startContainer{
background-color: #03989E;
display: grid;
justify-items: center;
width: 97%;
height: auto;
margin-left: 10px;
}
.startProfile{
width: 20em;

}
.startTitle{
font-size: 3em;
color:#F9F7DC;
margin-top: -30px;
font-weight:900;
}
.startName{
font-size: 2em;
color:#F9F7DC;
margin-top: -40px;
}
.startIcon{
font-size:5vmax;
color: #CB7F7A;
margin-top: -30px;
}
.startParagraph{
color:#9aaaf3;
font-size: 1.5em;
margin-top: 0px;
}