.footerContainer{
    width: 97%;
    height: 100%;
    background-color: #2c3e50;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

.footerTitle{
    font-size: 1vmax;
    color: #F9F7DC;

}